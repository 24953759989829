import React from 'react';
import './App.css';

function App() {
  return (
    <div className="home">
      <div className="row around-xs">

        <div className="col-xs-12 col-sm-10">
          <div className="box">
            <h1>418 I&apos;m a teapot</h1>
            <p>I haven't finished building my website yet!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
